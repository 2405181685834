<template>
  <b-container
    fluid
    class="mt-2 col-12"
    align-h="center"
  >
    <div
      v-if="!addEditMode"
      class="padding-bottom-64"
    >
      <b-row
        class="mintui__background-f6fafb"
      >
        <b-row
          class="col-md-12"
        >
          <h4
            class="aiops_mineral_green_heading4 padding-top-24 padding-left-32"
          >FILTERS</h4>
        </b-row>
        <b-row
          class="col-md-12 padding-bottom-24"
        >
          <b-col
            class="col-md-3 pl-0"
          >
            <b-form-group
              class="aiops_label_20 padding-left-32"
              label-for="typeIssue"
            >
              <span
                class="aiops_neutral_blue_heading4"
              >
                ROLE
              </span>
              <b-form-select
                id="typeIssue"
                v-model="selectedRole"
                :options="itemsRolesFilter"
                @change="filterUsers()"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="col-md-3 pl-0"
          >
            <b-form-group
              class="aiops_label_20 padding-left-32"
              label-for="typeIssue"
            >
              <span
                class="aiops_neutral_blue_heading4"
              >
                STATUS
              </span>
              <b-form-select
                id="typeIssue"
                v-model="selectedStatus"
                :options="itemsStatusFilter"
                @change="filterUsers()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
      <b-row
        class="margin-bottom-24 margin-top-24"
      >
        <b-col
          class="col-sm-12 col-md-1 pl-0"
        >
          <div class="aiops__users--button-actions">
            <b-button
              class="ibop-gothic-button"
              @click="addRoleMode"
            >
              <b-img
                class="mintui_add_user"
              />
              <span class="align-middle aiops_label_14_3e7282 padding-left-8">Add</span>
            </b-button>
          </div>
        </b-col>
        <b-col
          offset-md="9"
          class="col-sm-12 col-md-2 pr-0"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="searchValue"
              autocomplete="off"
              type="search"
              placeholder="Search"
              @update="filterUsers()"
            />
            <b-input-group-append
              is-text
              class="icon-append"
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="home-page">
        <b-table
          id="tableUsers"
          ref="table-users"
          refreshed
          responsive
          noCollapse
          no-border-collapse
          show-empty
          hover
          :current-page="currentPage"
          :items="getUsers"
          :fields="fields"
          thead-class="colorHead"
          tbody-class="colorBody"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #head()="data">
            <span class="colorHead">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #cell()="data">
            <span class="colorHead">{{ data.value | filterNullValue }}</span>
          </template>
          <template #cell(enabled)="data">
            <span class="colorHead">{{ data.value | filterEnabledValue }}</span>
          </template>enabled
          <template
            v-slot:cell(action)="data"
            class="aiops_model_table_col_action text-right float-lg-right"
          >
            <b-img
              class="mintui_edit_user float-lg-right"
              :alt="data.field.label"
              @click="editUser(data.item)"
            />
          </template>
        </b-table>
      </b-row>
      <!-- <b-row>
        <b-col class="d-flex justify-content-center flex-wrap pt-0 margin-bottom-48">
          <b-pagination
            v-model="currentPage"
            :value="currentPage"
            :per-page="10"
            limit="8"
            ellipsis-text="ººº"
            hide-goto-end-buttons
            :active="true"
            first-number
            last-number
            class="pagination-danger mt-2"
            aria-controls="table-tickets"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row> -->
    </div>
    <div
      v-if="addEditMode"
      class="padding-bottom-64"
    >
      <b-row
        class="padding-bottom-24"
        align-h="between"
      >
        <b-col>
          <h2
            class="aiops_mineral_green_heading2"
          >
            <b-img
              class="mintui_back_green"
              @click="addRoleMode"
            />
            {{ actionForUser }} User
          </h2>
        </b-col>
        <b-col
          offset="8"
          class="col-md-2"
        >
          <div class="aiops__users--button-actions-add-role">
            <b-button
              class="ibop-salmon-pearl-button mr-1"
              @click="addRoleMode"
            >
              <span class="align-middle aiops_paragraph_accent-red_bold">Cancel</span>
            </b-button>
            <b-button
              v-if="!editMode"
              class="ibop-mineral-green-button"
              @click="addNewUser()"
            >
              <span class="align-middle aiops_label_white_14">Save</span>
            </b-button>
            <b-button
              v-if="editMode"
              class="ibop-mineral-green-button"
              @click="updateUser()"
            >
              <span class="align-middle aiops_label_white_14">Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mintui__background-f6fafb"
      >
        <validation-observer
          ref="aiopsNewUser"
        >
          <b-form>
            <div
              class="row col-md-12 aiops__roles-role-name"
            >
              <b-col
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First name"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="firstName"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      FIRST NAME
                    </span>
                    <b-form-input
                      id="firstName"
                      v-model="newUser.firstName"
                      autocomplete="off"
                      placeholder="Type the Firstname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last name"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="lastName"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      LAST NAME
                    </span>
                    <b-form-input
                      id="lastName"
                      v-model="newUser.lastName"
                      autocomplete="off"
                      placeholder="Type the Lastname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!editMode"
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="email"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      EMAIL
                    </span>
                    <b-form-input
                      id="email"
                      v-model="newUser.email"
                      autocomplete="off"
                      placeholder="Type the Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Group"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="group"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      GROUP
                    </span>
                    <b-form-select
                      id="group"
                      v-model="newUser.groups"
                      :options="itemsRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="status"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      STATUS
                    </span>
                    <b-form-select
                      id="status"
                      v-model="newUser.enabled"
                      :options="itemsStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!editMode"
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="User name"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="username"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      USER NAME
                    </span>
                    <b-form-input
                      id="username"
                      v-model="newUser.username"
                      autocomplete="off"
                      placeholder="Type the Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="editMode"
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="User name"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="username"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      USER NAME
                    </span>
                    <b-form-input
                      id="username"
                      v-model="newUser.username"
                      autocomplete="off"
                      disabled
                      placeholder="Type the Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!editMode"
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  name="Password"
                  rules="required"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="password"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      PASSWORD (*)
                    </span>
                    <b-form-input
                      id="password"
                      v-model="newUser.password"
                      autocomplete="off"
                      type="password"
                      placeholder="Type the Password"
                      @input="checkPassValue"
                    />
                    <span
                      v-if="!!passwordRule"
                    >
                      <li
                        v-for="(rule, index) in rulesList"
                        :key="index"
                        :class="rule.class"
                      >
                        <small
                          :class="rule.class"
                        >{{ rule.message }}</small>
                      </li>
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="editMode"
                class="col-sm-12 col-md-4 pl-0"
              >
                <validation-provider
                  name="Password"
                >
                  <b-form-group
                    class="aiops_label_20"
                    label-for="password"
                  >
                    <span
                      class="aiops_neutral_blue_heading4"
                    >
                      PASSWORD (*)
                    </span>
                    <b-form-input
                      id="password"
                      v-model="newUser.password"
                      autocomplete="off"
                      type="password"
                      placeholder="Type the Password"
                      @input="checkPassValue"
                    />
                    <span
                      v-if="!!passwordRule"
                    >
                      <li
                        v-for="(rule, index) in rulesList"
                        :key="index"
                        :class="rule.class"
                      >
                        <small
                          :class="rule.class"
                        >{{ rule.message }}</small>
                      </li>
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {
  BContainer,
  BTable,
  BTr,
  BTh,
  BPagination,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BImg,
} from 'bootstrap-vue'
import store from '@/store'
import utilsAiops from '@/@core/utils/aiops/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, password } from '@validations'

const {
  loading,
  loadingProccessSpinner,
  setMessage,
} = utilsAiops()

export default {
  components: {
    BContainer,
    BTable,
    BTr,
    BTh,
    BPagination,
    BButton,
    BRow,
    BCol,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  filters: {
    filterNullValue(val) {
      const newVal = val || '---'
      return newVal
    },
    filterEnabledValue(val) {
      const newVal = val ? 'Active' : 'Inactive'
      return newVal
    },
  },
  data() {
    return {
      email,
      required,
      password,
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      actionForUser: '',
      addEditMode: false,
      editMode: false,
      selectedRole: null,
      selectedStatus: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        // {
        //   key: 'checks',
        //   label: '',
        //   sortable: false,
        //   class: 'backGroundHead',
        // },
        {
          key: 'username',
          label: 'User name',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'firstName',
          label: 'First name',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'lastName',
          label: 'Last name',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'role',
          Label: 'Role',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'enabled',
          Label: 'Status',
          sortable: true,
          class: 'pl-3',
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          class: 'pl-3',
        },
      ],
      newUser: {
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
        groups: null,
        enabled: null,
      },
      userDataForm: {
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
        groups: null,
        enabled: null,
      },
      itemsUsers: [],
      userListCompleted: [],
      itemsRoles: [],
      itemsRolesFilter: [],
      itemsStatus: [
        {
          value: null, text: 'Select Status',
        },
        {
          value: true, text: 'Active',
        },
        {
          value: false, text: 'Inactive',
        },
      ],
      itemsStatusFilter: [
        {
          value: null, text: 'All',
        },
        {
          value: 'true', text: 'Active',
        },
        {
          value: 'false', text: 'Inactive',
        },
      ],
      groupList: [],
      selectMode: 'single',
      searchValue: null,
      query: null,
      passwordRule: undefined,
      passwordRuleObj: {},
      rulesList: [],
      correctPass: undefined,
    }
  },
  computed: {
    getUsers() {
      return this.itemsUsers
    },
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }
    this.getPasswordRule()
    this.welcomeAssist()
    this.getRoles()
  },
  methods: {
    dataTableUsers() {
      store.dispatch('userRolesAiops/findUsers', this.query)
        .then(response => {
          const { items, data } = response
          this.itemsUsers = items
          this.userListCompleted = data
          loading(false)
          loadingProccessSpinner(false)
        })
        .catch(() => {
          loading(false)
          loadingProccessSpinner(false)
        })
    },
    getRoles() {
      store.dispatch('userRolesAiops/getGroups')
        .then(response => {
          this.groupList = response
          const selectRolesData = response.map(group => ({ value: [{ id: group.id, name: group.name }], text: group.name }))
          const newAssignmentGroup = [
            { value: null, text: 'Select Role' },
            ...selectRolesData,
          ]
          this.itemsRoles = newAssignmentGroup
          const selectRolesDataFilter = response.map(group => ({ value: group.id, text: group.name }))
          const newAssignmentGroupFilter = [
            { value: null, text: 'All' },
            ...selectRolesDataFilter,
          ]
          this.itemsRolesFilter = newAssignmentGroupFilter
          return this.dataTableUsers()
        })
        .catch(() => {
          loading(false)
        })
    },
    addNewUser() {
      this.$refs.aiopsNewUser.validate().then(success => {
        loadingProccessSpinner(true)
        if (success && this.correctPass) {
          const payloadUser = this.newUser
          store.dispatch('userRolesAiops/createUsers', payloadUser)
            .then(() => {
              this.addEditMode = !this.addEditMode
              this.newUser = this.userDataForm
              return this.dataTableUsers()
            })
            .catch(e => {
              const errorData = e
              if (errorData?.status === 400 || errorData?.status === 409) {
                setMessage(this.$bvToast, 'warning', `${errorData.message}`)
                loadingProccessSpinner(false)
                loading(false)
                return
              }
              this.addEditMode = !this.addEditMode
              loadingProccessSpinner(false)
              loading(false)
            })
        } else {
          loadingProccessSpinner(false)
        }
      })
    },
    updateUser() {
      this.$refs.aiopsNewUser.validate().then(success => {
        loadingProccessSpinner(true)
        if (success && this.correctPass) {
          const payloadUser = this.newUser
          if (payloadUser.password === '') {
            delete payloadUser.password
          }
          const { id } = payloadUser
          store.dispatch('userRolesAiops/updateUser', { payloadUser, id })
            .then(() => {
              this.addEditMode = !this.addEditMode
              this.newUser = this.userDataForm
              return this.dataTableUsers()
            })
            .catch(e => {
              const errorData = e
              if (errorData?.status === 400 || errorData?.status === 409) {
                setMessage(this.$bvToast, 'warning', `${errorData.message}`)
                loadingProccessSpinner(false)
                loading(false)
                return
              }
              this.addEditMode = !this.addEditMode
              loadingProccessSpinner(false)
              loading(false)
            })
        } else {
          loadingProccessSpinner(false)
        }
      })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
    addRoleMode() {
      this.actionForUser = 'New'
      this.editMode = false
      this.userDataForm = {
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
        groups: null,
        enabled: null,
      }
      this.newUser = this.userDataForm
      this.rulesList = []
      this.addEditMode = !this.addEditMode
    },
    editUser(items) {
      loadingProccessSpinner(true)
      this.editMode = true
      this.actionForUser = 'Edit'
      this.selectedUser = items
      store.dispatch('userRolesAiops/getUserById', this.selectedUser.id)
        .then(response => {
          const user = response
          this.newUser = {
            id: user.id,
            username: user.username,
            password: '',
            firstName: user.firstName,
            lastName: user.lastName,
            groups: user.groups,
            enabled: user.enabled,
          }
          delete this.newUser.email
          this.checkPassValue(this.newUser.password)
          this.addEditMode = !this.addEditMode
          loadingProccessSpinner(false)
        })
        .catch(() => {
          this.addEditMode = !this.addEditMode
          this.checkPassValue()
          loadingProccessSpinner(false)
          loading(false)
        })
    },
    filterUsers() {
      loadingProccessSpinner(true)
      if (this.selectedRole && this.selectedStatus && this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&enabled=${this.selectedStatus}&search=${this.searchValue}`
      } else if (this.selectedRole && this.selectedStatus && !this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&enabled=${this.selectedStatus}`
      } else if (this.selectedRole && !this.selectedStatus && this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&search=${this.searchValue}`
      } else if (!this.selectedRole && this.selectedStatus && this.searchValue) {
        this.query = `?enabled=${this.selectedStatus}&search=${this.searchValue}`
      } else if (this.selectedRole && !this.selectedStatus && !this.searchValue) {
        this.query = `?groupId=${this.selectedRole}`
      } else if (!this.selectedRole && this.selectedStatus && !this.searchValue) {
        this.query = `?enabled=${this.selectedStatus}`
      } else if (!this.selectedRole && !this.selectedStatus && this.searchValue) {
        this.query = `?search=${this.searchValue}`
      } else {
        this.query = null
      }
      store.dispatch('userRolesAiops/findUsers', this.query)
        .then(response => {
          const { items, data } = response
          this.itemsUsers = items
          this.userListCompleted = data
          loading(false)
          loadingProccessSpinner(false)
        })
        .catch(() => {
          loading(false)
          loadingProccessSpinner(false)
        })
    },
    getPasswordRule() {
      store.dispatch('userRolesAiops/getPasswordRule')
        .then(passRule => {
          this.passwordRule = passRule[0].value
          const passwordRuleStr = this.passwordRule.replaceAll(')', '').replaceAll(' and', ',').replaceAll('(', ':')
          const passwordRuleObj = passwordRuleStr
          this.passwordRuleObj = passwordRuleObj
            .split(',')
            .map(x => x.split(':').map(y => y.trim()))
            .reduce((a, x) => {
              // eslint-disable-next-line no-param-reassign
              a[x[0]] = Number(x[1])
              return a
            }, {})
        })
        .catch(e => console.log(e))
    },
    checkPassValue(passVal) {
      if (!this.editMode || (this.editMode && passVal !== '')) {
        const rulesMessage = []
        const formatStandardCharacters = /^[a-zA-Z0-9-\s]*$/
        const formatUpperCase = /(?=.*[A-Z])/
        const formatLowerCase = /(?=.*[a-z])/
        const formatDigits = /(?=.*\d)/
        if (this.passwordRuleObj.specialChars) {
          if (!passVal.match(formatStandardCharacters)) {
            const objRule = {
              message: 'The password has special characters',
              class: 'aiops_label_14_3e7282',
              status: true,
            }
            rulesMessage.push(objRule)
          } else {
            const objRule = {
              message: 'The password requires special characters',
              class: 'text-danger',
              status: false,
            }
            rulesMessage.push(objRule)
          }
        }

        if (this.passwordRuleObj.upperCase) {
          if (passVal.match(formatUpperCase)) {
            const objRule = {
              message: 'The password has an upperCase',
              class: 'aiops_label_14_3e7282',
              status: true,
            }
            rulesMessage.push(objRule)
          } else {
            const objRule = {
              message: 'The password requires an upperCase',
              class: 'text-danger',
              status: false,
            }
            rulesMessage.push(objRule)
          }
        }

        if (this.passwordRuleObj.lowerCase) {
          if (passVal.match(formatLowerCase)) {
            const objRule = {
              message: 'The password has a lowerCase',
              class: 'aiops_label_14_3e7282',
              status: true,
            }
            rulesMessage.push(objRule)
          } else {
            const objRule = {
              message: 'The password requires a lowerCase',
              class: 'text-danger',
              status: false,
            }
            rulesMessage.push(objRule)
          }
        }

        if (this.passwordRuleObj.digits) {
          if (passVal.match(formatDigits)) {
            const objRule = {
              message: 'The password has a digit',
              class: 'aiops_label_14_3e7282',
              status: true,
            }
            rulesMessage.push(objRule)
          } else {
            const objRule = {
              message: 'The password requires a digit',
              class: 'text-danger',
              status: false,
            }
            rulesMessage.push(objRule)
          }
        }

        if (this.passwordRuleObj.length) {
          if (passVal.length >= this.passwordRuleObj.length) {
            const objRule = {
              message: 'The password has a correct length',
              class: 'aiops_label_14_3e7282',
              status: true,
            }
            rulesMessage.push(objRule)
          } else {
            const objRule = {
              message: `The password requires a minimum length of ${this.passwordRuleObj.length}`,
              class: 'text-danger',
              status: false,
            }
            rulesMessage.push(objRule)
          }
        }
        this.rulesList = rulesMessage
        this.correctPass = this.rulesList.every(rule => rule.status === true)
        return
      }
      this.correctPass = true
      this.rulesList = []
    },
  },
}
</script>

<style lang="scss" scoped>
  .backGroundHead {
    background-color: #2f2b6d;
  }

::v-deep {

  .b-table-sticky-header > .table.b-table > tbody.colorBody > tr > td {
    padding: 0.72rem 5px !important;
  }

  .colorHead {
    background-color: #eef7f9;
    color: #005573;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #fff;
    color: #6f9ca8;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
    overflow-y: scroll !important;
    padding: 0.72rem 5px !important;
  }

  b-pagination .page-item .active .page-link {
    border-radius: 5rem;
    background-color: #12a71e !important;
  }

  .table tbody tr {
    height: 55px !important;
  }
}

.pagination {
  ::v-deep {
    .page-item.active {
      background-color: transparent;
      border-radius: 0;
    }
    .page-item.active .page-link {
      background: #ea5455 !important;
    }
    .page-link {
      background-color: transparent !important;
      color: #6f9ca8;
      font-weight: 200;
    }
    .page-link:hover {
      background-color: transparent !important;
      color: #6f9ca8;
    }
  }
}

.aiops_model_table_col_action {
  text-align: right;
}

.aiops__users--button-actions {
  display: flex;
  justify-content: space-evenly;
}

.aiops__users--button-actions-add-role {
  display: flex;
  justify-content: flex-end;
}

.aiops__roles-role-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 48px;
  height: auto;
}
</style>
